export interface ZustandGameStore extends ZustandGameStoreEntity {
  addBet: (b: Bet) => void;
  setAnimationType: (a: EAnimationType) => void;
  setLoadingStatus: (f: boolean) => void;
  setWinStatus: (l: EResultStatus) => void;
}

interface ZustandGameStoreEntity {
  isLoading: boolean;
  animationType: EAnimationType;
  winState: EResultStatus;

  bets: Bet[];
}

export type Bet = {
  isTail: boolean;
  isWin: boolean;
};

export const enum EAnimationType {
  Idle,
  Tail,
  Head,
}

export const enum EResultStatus {
  Idle,
  Win,
  Lose,
}
