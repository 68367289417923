import React, { useEffect, useRef, useState } from 'react';
import { Socket, io } from 'socket.io-client';

import styles from './HOCGameInit.module.css';
import { SendCommand } from './types';
import { useZustandGameStore } from '../../store/store';
import { EAnimationType, EResultStatus } from '../../store/types';

type Props = {
  children: React.ReactElement;
};

const HOCGameInit = ({ children }: Props) => {
  const [isAvailableGames, setAvailableGames] = useState(false);

  const [clientInfo, setClientInfo] = useState<null | { accessToken: string }>(
    null
  );

  const setLoaderState = useZustandGameStore((store) => store.setLoadingStatus);

  const setAnimationType = useZustandGameStore(
    (store) => store.setAnimationType
  );

  const setWinState = useZustandGameStore((store) => store.setWinStatus);

  const addBet = useZustandGameStore((store) => store.addBet);

  const socket = useRef<null | Socket>(null);

  const callback = (event: any) => {
    try {
      const { data } = event;
      const { type, payload } = data;

      switch (type) {
        case 'clientInfo':
          setClientInfo(payload);
          break;

        default:
          break;
      }
    } catch (error) {
      console.log('🚀 ~ callback ~ error:', error);
    }
  };

  const sendCommand = (data: SendCommand) => {
    socket.current?.emit('client-coin-flip-spin', data);
  };

  useEffect(() => {
    window.addEventListener('message', callback, true);

    window?.parent?.postMessage({ type: 'in-house-game' }, '*');

    return () => {
      window.removeEventListener('message', callback, true);
    };
  }, []);

  useEffect(() => {
    if (!clientInfo) {
      return () => {
        socket.current?.disconnect();
        socket.current = null;
      };
    }

    socket.current = io(process.env.REACT_APP_URL as string);

    socket.current.on('connect', () => {
      socket.current?.emit('authorize-client', { clientInfo });
    });

    socket.current.on('verified', () => {
      setAvailableGames(true);
    });

    socket.current.on('bet-result', (result: { isWin: boolean; data: any }) => {
      const { isWin, data } = result;
      const { isTail } = data;

      setLoaderState(false);

      let animationType = EAnimationType.Idle;
      const winWithTail = isWin && isTail;
      const loseWithTail = !isWin && !isTail;

      const winWithHead = isWin && !isTail;
      const loseWithHead = !isWin && isTail;

      if (winWithTail || loseWithTail) {
        animationType = EAnimationType.Tail;
      }

      if (winWithHead || loseWithHead) {
        animationType = EAnimationType.Head;
      }

      const winState = isWin ? EResultStatus.Win : EResultStatus.Lose;

      setWinState(winState);

      setAnimationType(animationType);
      addBet({
        isTail,
        isWin,
      });
    });

    // Clean up the socket connection on unmount
    return () => {
      socket.current?.disconnect();
      socket.current = null;
    };
  }, [addBet, clientInfo, setAnimationType, setLoaderState, setWinState]);

  return (
    <>
      {!isAvailableGames && (
        <div className={styles.wrapperHOCGameInit}>Loading ...</div>
      )}

      {React.cloneElement(children, { sendCommand })}
    </>
  );
};

export default HOCGameInit;
