import cln from 'classnames';

import { useCallback, useEffect, useRef } from 'react';

import { sleep } from '../../utils/shared';
import { EAnimationType, EResultStatus } from '../../store/types';
import {
  ANIMATION_DURATION_LONG,
  FIRST_WHEEL,
  HEAD_RESULT,
} from '../../store/constants';

import styles from './GameZone.module.css';
import { useZustandGameStore } from '../../store/store';

const GameZone = () => {
  const isLoader = useZustandGameStore((store) => store.isLoading);

  const animation = useZustandGameStore((store) => store.animationType);

  const setAnimationType = useZustandGameStore(
    (store) => store.setAnimationType
  );

  const winState = useZustandGameStore((store) => store.winState);

  const setResultState = useZustandGameStore((store) => store.setWinStatus);

  const container = useRef<any>(null);

  const runInlineAnimation = useCallback(async () => {
    if (
      !container.current ||
      !container.current.style ||
      animation === EAnimationType.Idle
    ) {
      return;
    }

    // TODO MAKE ANIMATIOn
    container.current.style.transform = `rotateX(${FIRST_WHEEL}deg)`;
    container.current.style.scale = '1.3';

    await sleep(ANIMATION_DURATION_LONG);

    const result = animation === EAnimationType.Head ? -HEAD_RESULT : 0;

    container.current.style.transform = `rotateX(${result}deg)`;
    container.current.style.scale = '1';

    await sleep(ANIMATION_DURATION_LONG / 2);

    setAnimationType(EAnimationType.Idle);
  }, [animation, setAnimationType]);

  const resetAnimation = useCallback(async () => {
    await sleep(ANIMATION_DURATION_LONG * 2);

    setResultState(EResultStatus.Idle);
  }, [setResultState]);

  useEffect(() => {
    runInlineAnimation();
  }, [runInlineAnimation]);

  useEffect(() => {
    if (winState === EResultStatus.Idle) {
      return;
    }

    resetAnimation();
  }, [winState, resetAnimation]);

  return (
    <div className={styles.gameZoneWrapper}>
      <div
        className={cln(styles.loseText, {
          [styles.scaleAnimation]: winState === EResultStatus.Lose,
        })}
      >
        Lose
      </div>

      <div className={styles.gameInfo}>
        <div className={styles.box}>
          <div className={styles.boxTitle}>Ряд</div>
          <div className={styles.result}>-</div>
        </div>
        <div
          className={cln(styles.middleBox, {
            [styles.isLoading]: isLoader,
          })}
        >
          <div className={styles.coinInners} ref={container}>
            <div className={cln(styles.iconCoin, styles.front)}>
              <img src="/assets/coin-head.webp" alt="" />
            </div>
            <div className={cln(styles.iconCoin, styles.back)}>
              <img src="/assets/coin-tail.webp" alt="" />
            </div>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.boxTitle}>Odd</div>
          <div className={styles.result}>x1.9804</div>
        </div>
      </div>

      <div
        className={cln(styles.winText, {
          [styles.scaleAnimation]: winState === EResultStatus.Win,
        })}
      >
        Green
      </div>
    </div>
  );
};

export default GameZone;
