import { useRef, useState } from 'react';
import cln from 'classnames';

import styles from './Buttons.module.css';
import { useZustandGameStore } from '../../store/store';
import { EAnimationType } from '../../store/types';

type Props = {
  handleBet: (bet: number, isTail?: boolean) => void;
};

const Buttons = (props: Props) => {
  const isLoader = useZustandGameStore((store) => store.isLoading);

  const animationType = useZustandGameStore((store) => store.animationType);

  const { handleBet } = props;

  const [currentBet, setCurrentBet] = useState<number>(100.0);

  const audio = useRef<HTMLAudioElement>(
    new Audio(
      '/assets/short-success-sound-glockenspiel-treasure-video-game-6346.mp3'
    )
  );

  const onSubmit = (isTail: boolean) => () => {
    if (audio.current) {
      audio.current.pause();
      audio.current.volume = 0.2;
      audio.current.currentTime = 0;
      audio.current.play();
    }
    handleBet(currentBet, isTail);
  };

  const handleSetPreset = (reducing: boolean) => () => {
    if (reducing) {
      const newBet = +(currentBet / 2).toFixed(2);

      if (newBet < 10) {
        return;
      }

      setCurrentBet(newBet);

      return;
    }

    const newBet = +(currentBet * 2).toFixed(2);

    if (newBet > 1000) {
      return;
    }

    setCurrentBet(newBet);
  };

  return (
    <div className={styles.wrapperButtons}>
      <div className={styles.betInfoWrapper}>
        <div className={styles.max} onClick={handleSetPreset(true)}>
          <div>1/2</div>
        </div>
        <div className={styles.moneyInfo}>
          <img src="/assets/coin.svg" alt="coin" />

          <div className={styles.stake}>{currentBet}</div>
        </div>
        <div className={styles.max} onClick={handleSetPreset(false)}>
          <div>2x</div>
        </div>
      </div>

      <div className={styles.betButtons}>
        <div
          className={cln(styles.button, {
            [styles.disabled]:
              isLoader || animationType !== EAnimationType.Idle,
          })}
          onClick={onSubmit(false)}
        >
          <img src="/assets/coin-head.webp" alt="head" className={styles.img} />

          <div className={styles.title}>Bet Head</div>

          <div className={styles.sum}>{currentBet * 1.98}.00 BC </div>
        </div>
        <div
          className={cln(styles.button, {
            [styles.disabled]:
              isLoader || animationType !== EAnimationType.Idle,
          })}
          onClick={onSubmit(true)}
        >
          <img src="/assets/coin-tail.webp" alt="head" className={styles.img} />

          <div className={styles.title}>Bet Tail</div>

          {/* need to make it from server config */}
          <div className={styles.sum}>{currentBet * 1.98}.00 BC</div>
        </div>
      </div>
    </div>
  );
};

export default Buttons;
