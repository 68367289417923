import { create } from 'zustand';

import { EAnimationType, EResultStatus, ZustandGameStore } from './types';

export const useZustandGameStore = create<ZustandGameStore>((set, get) => ({
  isLoading: false,
  animationType: EAnimationType.Idle,
  winState: EResultStatus.Idle,

  bets: [],

  addBet: (b) => {
    const { bets } = get();

    set({ bets: [b, ...bets] });
  },
  setAnimationType: (animationType) => {
    set({ animationType });
  },
  setLoadingStatus: (isLoading) => {
    set({ isLoading });
  },
  setWinStatus: (winState) => {
    set({ winState });
  },
}));
