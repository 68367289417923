import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import HOCGameInit from './components/HOCGameInit';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <HOCGameInit>
      <App />
    </HOCGameInit>
  </React.StrictMode>
);

reportWebVitals();
