import { useState } from 'react';

import GameHeader from './components/GameHeader';
import GameZone from './components/GameZone';
import Buttons from './components/Buttons';

import styles from './App.module.css';
import { useZustandGameStore } from './store/store';

function App({ sendCommand }: { sendCommand?: any }) {
  const [isMuliGame, setMultiGameState] = useState(false);

  const setLoaderState = useZustandGameStore((store) => store.setLoadingStatus);

  const toggleState = () => setMultiGameState(!isMuliGame);

  const run = async (stake: number, isTail = false) => {
    setLoaderState(true);

    sendCommand({
      type: 'spin',
      stake,
      isTail,
    });
  };

  return (
    <div className={styles.wrapperApp}>
      {/* <GameHeader isMuliGame={isMuliGame} toggleState={toggleState} /> */}

      <div className={styles.gameZone}>
        <GameZone />
      </div>

      <Buttons handleBet={run} />
    </div>
  );
}

export default App;
